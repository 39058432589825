.contact-us {
    padding: 50px 0px;
}

.contact-us-heading {
    text-align: center;
    width: 100%;
    padding: 10px 0px;
    border-radius: 50px;
    background: linear-gradient(90deg, #FFA71E 0%, #D82700 100%);
}

.contact-us-heading h4 {
    margin-bottom: 0;
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    color: white;

}

.contact-us-number {
    text-align: center;
    margin-top: 20px;
}

.contact-us-number h4 {
    font-family: 'Lato', sans-serif;
    font-weight: 800;
}

.social-media-panel{
    display: flex;
    flex-wrap: nowrap;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    justify-content: space-around;
}

.whatsapp-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
    padding: 10px 10px;
    border-radius: 55px;
    background: linear-gradient(180deg, #35DB23 0%, #27A11A 100%);
}
.telegram-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
    padding: 10px 10px;
    border-radius: 55px;
    background: linear-gradient(180deg, #1C8ADB 0%, #006DBD 100%);}

.icon-img{
    width: 20%;
}

.icon-img img{
    width: 100%;
    object-fit: cover;
}
.icon-text h5{
    margin-bottom: 0;
    color: white;
}

.whatsapp-icon:hover,.telegram-icon:hover{
    cursor: pointer;
}










/* ###################################### media quary ############################################################################### */

@media only screen and (max-width:576px) {

    .icon-text h5{
        margin-bottom: 0;
        color: white;
        font-size: 18px;
    }
}

@media only screen and (max-width:380px) {
    .icon-text h5{
        margin-bottom: 0;
        color: white;
        font-size: 14px;
    }

    .social-media-panel{
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .whatsapp-icon, .telegram-icon{
        margin: 10px;
        width: 130px;
    }


}