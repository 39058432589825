.navbar {
    height: 90px;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.20);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

.logo {
    width: 120px;
}

.logo img {
    width: 100%;
    object-fit: contain;
}

.nav-link {
    font-family: 'Inter', sans-serif;
    color: #0F1E36;
    font-size: 16px;
    margin: 0 20px;
    font-weight: 600;
}

.nav-link:hover {
    color: #EE502D;
}

.active {
    color: #EE502D !important;
}

/* ###################################### media quary ############################################################################### */


@media only screen and (max-width:991px) {

    .navbar-collapse {
        width: 100%;
        margin-top: 8px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: white;
        box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.20);


    }

}
