.market-panel-chart {
    padding: 50px 0px;
}

.market-chart {
    max-width: 75%;
    width: 90%;
    margin: 0 auto;
}



.market-chart-main-title .underline {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.market-chart-main-title {
    text-align: center;
    max-width: 360px;
    margin: 0 auto;
}

.market-chart-main-title h2 {
    margin-bottom: 0;
    font-family: 'Jomhuria', cursive;
    font-size: 70px;
    color: #00365C;
    text-transform: capitalize;
    line-height: 40px;
}

.market-chart-main-title h2 span {
    font-family: 'Jomhuria', cursive;
    font-size: 70px;
    line-height: 40px;
    color: #E100C6;
}

.market-chart-main-title hr {
    color: #FF9B00;
    margin: 0;
    border: 0;
    border-top: 3px solid;
    opacity: 1;
}

.market-chart-title {
    background-color: #FFB541;
    text-align: center;
    margin-top: 50px;
    padding: 10px 0px;
}

.market-chart-title h3 {
    color: #000;
    font-family: Lato;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.market-chart-table {
    margin-top: 20px;
}

.market-chart-table .table-head-date {
    width: 10%;
}

.market-chart-table .table-head-time {
    width: 10%;
}

.market-chart-table .table-head-date,
.market-chart-table .table-head-time {
    background-color: #B5009F !important;
    vertical-align: middle;
    padding: 10px 0px !important;
}

.market-chart-table .table-head-date h5 {
    font-family: lato;
    font-weight: 700;
    color: white;
    margin-bottom: 0px;
}

.market-chart-table .table-head-time h6 {
    font-family: lato;
    font-weight: 700;
    color: white;
    margin-bottom: 0px;
    letter-spacing: 1px;
}

.market-chart-table .table-date {
    background-color: #CC00B3 !important;
    vertical-align: middle;
    padding: 10px 0px;
}

.market-chart-table .table-body-date h6 {
    font-family: lato;
    font-weight: 700;
    color: white;
    margin: 7px;
    margin-bottom: 0px;
}

.market-chart-table .table-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-result .result-ank .star {
    font-size: 24px;
    width: 35px;
    margin-top: 0px;
    border: 2px solid #FF6AED;
    padding: 5px 0px 5px 4px;

}

.table-result .result-ank .star:nth-child(2) {
    border-top: 1px;
    border-bottom: 1px;
}


.table-result .result-ank h6 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;
    text-align: center;
}



.table-result .main-result h6 {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-top: 8px;
    margin-bottom: 0px;

}

.table-result .main-result h6 .star {
    margin-top: 7px;
}

.market-chart-table .table-body td {
    padding: 0;
}

.main-result-data .star {
    font-family: Inria Sans;
    font-size: 28px !important;
}

.main-result-data h6 {
    font-family: lato;
    font-size: 20px;
    font-weight: 700;
}

.result-data .star {
    font-family: lato;
    font-size: 16px !important;
}

.new-star {
    font-family: Inria Sans;
    font-size: 24px;
    line-height: 22px;
    width: 35px;
    margin-top: 0px;
    border: 2px solid #FF6AED;
    padding: 8px 0px 0px 0px;

}

.table-result .result-ank .new-star:nth-child(2) {
    border-top: 1px;
    border-bottom: 1px;
}




/* ###################################### media quary ############################################################################### */



@media only screen and (max-width:1920px) {}

@media only screen and (max-width:1680px) {

    .market-chart {
        max-width: 75%;
        width: 90%;
        margin: 0 auto;
    }

}

@media only screen and (max-width:1540px) {
    .market-chart {
        max-width: 85%;
        width: 90%;
        margin: 0 auto;
    }

}

@media only screen and (max-width:1440px) {
    .market-chart {
        max-width: 90%;
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width:1366px) {}


@media only screen and (max-width:1230px) {

    .market-chart .table-head-date h5 {
        font-size: 18px;
    }

    .market-chart .table-head-time h6 {
        font-size: 16px;
    }

    .market-chart .table-body-date h6 {
        font-size: 14px;

    }
}

@media only screen and (max-width:1030px) {

    .market-chart-table {
        overflow-x: scroll;
    }

    .market-chart-table table {
        width: 920px;
    }

    .market-chart-table::-webkit-scrollbar {
        display: none;
    }

    .market-chart-table::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    .market-chart-table::-webkit-scrollbar-thumb {
        background: #2E3F52;
        border-radius: 10px;
    }

}

@media only screen and (max-width:991px){

    .market-chart-main-title {
        max-width: 340px;
    }
    
    .market-chart-main-title h2 ,.market-chart-main-title h2 span{
        font-size: 65px;
    }


}

@media only screen and (max-width:767px) {

    .market-chart-main-title {
        max-width: 310px;
    }
    
    .market-chart-main-title h2 ,.market-chart-main-title h2 span{
        font-size: 60px;
    }
}

@media only screen and (max-width:576px) {

    .market-chart-main-title {
        max-width: 290px;
    }
    
    .market-chart-main-title h2 ,.market-chart-main-title h2 span{
        font-size: 55px;
    }
}

@media only screen and (max-width:380px) {

    .market-chart-main-title {
        max-width: 240px;
    }
    
    .market-chart-main-title h2 ,.market-chart-main-title h2 span{
        font-size: 45px;
    }

}