.header {
    width: 100%;
    min-height: 80vh;
    background: url(../../Images/header-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px;
    margin-top: 90px;
}

.header .container {
    position: relative;
}

.header-content {
    min-height: 80vh;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.header-text-content {
    margin-top: 100px;
}

.anna-vector {
    width: 45vh;
    display: flex;
    justify-content: center;
    align-items: end;

}

.anna-vector img {
    width: 100%;
    object-fit: contain;
}

.main-heading h1 {
    font-family: 'Lato', sans-serif;
    color: #F4BD31;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 75px;
    font-weight: 900;
    margin-bottom: 0px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

.sub-heading-1 h4 {
    font-family: 'Lato', sans-serif;
    color: white;
    margin-bottom: 0px;
}



.download-btn {
    max-width: 320px;
    margin: auto;
    margin-top: 10%;
    padding: 3px;
    border-radius: 50px;
    border: 2px solid white;
    background-color: transparent;
    animation: bounce 1s ease infinite;
}

.download-btn:hover {
    cursor: pointer;
}

.download-content {
    margin: auto;
    padding: 15px 0px;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background: #FFC700;
    text-align: center;
    position: relative;
}

.download-content .download-content-text {
    width: 90%;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

}

.download-content.disable{
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
}

.download-content .download-content-text h5 {
    vertical-align: middle;
    font-family: 'Lato', sans-serif;
    color: black;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.download-content-text .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
}

.shade img {
    width: 100%;
    object-fit: cover;
}

@keyframes bounce {
    0% {
        transform: translateY(0%);
        animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    }

    50% {
        transform: translateY(20%);
        animation-timing-function: cubic-bezier(0, 0, .2, 1);
    }

    100% {
        transform: translateY(0%);
        animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    }

}







/* ###################################### media quary ############################################################################### */




@media only screen and (max-width:1680px) {
    .main-heading h1 {
        font-size: 70px;
    }
}

@media only screen and (max-width:1440px) {
    .main-heading h1 {
        font-size: 65px;
    }

    /* .anna-vector {
        width: 35%;
    } */
}

@media only screen and (max-width:1366px) {

    .sub-heading-1 h4 {
        font-size: 20px;
    }
}

@media only screen and (max-width:1230px) {

    .main-heading h1 {
        font-size: 60px;
    }

    /* .anna-vector {
        width: 35%;
    } */
}

@media only screen and (max-width:1199px) {

    .main-heading h1 {
        font-size: 60px;
    }

    /* .anna-vector {
        width: 40%;
    } */
}

@media only screen and (max-width:991px) {

    .header {
        min-height: 100%;
    }

    .header-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .header-text-content {
        margin-top: 50px;
        text-align: center;
    }

    .main-heading h1 {
        font-size: 55px;
    }

    .anna-vector {
        margin-top: 50px;
        width: 60%;
    }

}


@media only screen and (max-width:767px) {
    .anna-vector {
        margin-top: 50px;
        width: 70%;
    }
}

@media only screen and (max-width:680px) {

    .main-heading h1 {
        font-size: 50px;
    }
}

@media only screen and (max-width:576px) {
    .main-heading h1 {
        font-size: 42px;
    }
}

@media only screen and (max-width:380px) {
    .main-heading h1 {
        font-size: 40px;
    }

    .anna-vector {
        margin-top: 50px;
        width: 80%;
    }
}