.footer {
    background: linear-gradient(135deg, #580084 0%, #670084 23.96%, #84007B 48.44%, #8C007B 73.44%, #8C007B 100%);
    padding: 50px 0px 20px;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    gap: 30px;
}


.company-logo {
    max-width: 350px;
    padding: 65px 10px;
    border-radius: 40px;
    background-color: white;
}

.logo-img {
    width: 80%;
    margin: 0 auto;
}

.logo-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.company-disclaimer {
    width: 60%;
}

.disclaimer-content h6 {
    color: #FFF;
    text-align: justify;
    font-family: Lato;
    line-height: 28px;
    text-transform: capitalize;
}


.footer .download-btn {
    max-width: 250px;
    margin: 0;
    margin-top: 20px;
    padding: 3px;
    border-radius: 50px;
    border: 2px solid white;
    background-color: transparent;
    animation: none;
}

.footer .download-content .download-content-text {
    width: 90%;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

}

.footer .download-content.disable {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
}

.footer .download-content .download-content-text h5 {
    vertical-align: middle;
    font-family: 'Lato', sans-serif;
    color: black;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.footer .download-content-text .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
}

.shade img {
    width: 100%;
    object-fit: cover;
}

.footer hr {
    margin-top: 30px;
    color: white;
    opacity: 1;
}

.copyright {
    margin-top: 30px;
    text-align: center;
}

.copyright h6 {
    color: white;
}

.logo-shadow {
    width: 85%;
    margin: 0 auto;
    margin-top: 25px;
    height: 20px;
    opacity: 0.35;
    background: radial-gradient(77.85% 73.21% at 50.00% 50.00%, #000 0%, rgba(88, 0, 132, 0.00) 100%);
    filter: blur(10px);
}

.company-disclaimer .logo-shadow {
    width: 30%;
    margin: 0;
    margin-top: 25px;
    height: 15px;
    opacity: 0.35;
    background: radial-gradient(77.85% 73.21% at 50.00% 50.00%, #000 0%, rgba(88, 0, 132, 0.00) 100%);
    filter: blur(10px);
}










/* ###################################### media quary ############################################################################### */





@media only screen and (max-width:1230px) {

    .footer .container {
        max-width: 1140px;
    }

}


@media only screen and (max-width:850px) {

    .footer-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .company-disclaimer {
        width: 80%;
    }

    .footer .download-btn {
        margin: 0 auto;
        margin-top: 30px;
    }

    .company-disclaimer .logo-shadow {
        margin: 0 auto;
        margin-top: 10px;
    }
}


@media only screen and (max-width:576px) {
    .copyright h6 {
        font-size: 12px;
    }
}

@media only screen and (max-width:380px) {}