.pointbar{
    padding: 100px 0px;
    background: linear-gradient(45deg,#580084, #670084, #84007B, #8C007B, #8C007B);
}

.pointbar-content{
    max-width: 95%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;

}

.pointbar-box{
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.pointbar-icon{
    width: 80px;
    height: 80px;
}

.pointbar-icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pointbar-text h3{
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 3px;
}
.pointbar-text h5{
    color: white;
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    margin-bottom: 0;
}




/* ###################################### media quary ############################################################################### */



@media only screen and (max-width:1920px) {}

@media only screen and (max-width:1680px) {}

@media only screen and (max-width:1540px) {}

@media only screen and (max-width:1440px) {}

@media only screen and (max-width:1366px) {

   
    .pointbar-icon{
        width: 70px;
        height: 70px;
    }

    .pointbar-text h3{
        font-size: 22px;
    }
    .pointbar-text h5{
        font-size: 17px;
    }


}

@media only screen and (max-width:1230px) {}

@media only screen and (max-width:1152px) {}

@media only screen and (max-width:1080px) {

    .pointbar-icon{
        width: 60px;
        height: 60px;
    }

   
}

@media only screen and (max-width:1024px) {

    .pointbar-icon{
        width: 50px;
        height: 50px;
    }

    .pointbar-text h3{
        font-size: 20px;
    }
    .pointbar-text h5{
        font-size: 16px;
    }
}

@media only screen and (max-width:991px) {

    .pointbar{
        padding: 50px 0px;
    }

    .pointbar-content{
        flex-wrap: wrap;
        justify-content: center;
    }

     .pointbar-box{
        width: 40%;
        justify-content: flex-start;
        margin: 20px 0px;
    }


}

@media only screen and (max-width:850px) {}

@media only screen and (max-width:767px) {

    .pointbar-box{
        width: 45%;
        margin: 20px 10px;
    }

    .pointbar-text h3{
        font-size: 18px;
    }
    .pointbar-text h5{
        font-size: 14px;
    }
}

@media only screen and (max-width:680px) {}

@media only screen and (max-width:576px) {

    .pointbar-box{
        /* border: 1px solid red; */
        width: 65%;
    }

    .pointbar-text h3{
        font-size: 18px;
    }
    .pointbar-text h5{
        font-size: 16px;
    }

}

@media only screen and (max-width:380px) {

    .pointbar-box{
        /* border: 1px solid red; */
        width: 75%;
        gap: 10px;
    }

    .pointbar-icon{
        width: 40px;
        height: 40px;
    }

    .pointbar-text h3{
        font-size: 18px;
    }
    .pointbar-text h5{
        font-size: 14px;
    }
}