.market-result {
    padding: 50px 0px 50px 0px;
}


.market-result .underline {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.market-result-heading {
    text-align: center;
    max-width: 360px;
    margin: 0 auto;
}

.market-result-heading h2 {
    margin-bottom: 0;
    font-family: 'Jomhuria', cursive;
    font-size: 70px;
    color: #00365C;
    text-transform: capitalize;
    line-height: 40px;
}

.market-result-heading h2 span {
    font-family: 'Jomhuria', cursive;
    font-size: 70px;
    line-height: 40px;
    color: #E100C6;
}

.market-result-heading hr {
    color: #FF9B00;
    margin: 0;
    border: 0;
    border-top: 3px solid;
    opacity: 1;
}

.market-result-boxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
}

.market-result-box {
    width: 45%;
    margin: 15px;
    height: 150px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.20);

    display: flex;
    justify-content: center;
    gap: 5px;
    position: relative;
    padding: 15px 30px;
}

.market-result-box::before {
    content: '';
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 10px 10px;
    background: linear-gradient(135deg, #580084 0%, #670084 23.96%, #84007B 48.44%, #8C007B 73.44%, #8C007B 100%);
    z-index: 0;
}

.market-result-box-name,
.market-result-box-content {
    width: 50%;
}

.market-result-box-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid red; */

}

.market-result-box-name h3 {
    color: #0F1E36;
    font-family: Jost;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.market-time {
    display: flex;
    justify-content: space-between;
}

.open-time h6,
.close-time h6 {
    color: #0F1E36;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.time {
    font-size: 13px !important;
    font-weight: 400;
    margin-top: 4px;
}

.market-result-data {
    margin-top: 5px;
}

.market-result-data h4 {
    display: flex;
    font-family: Inria Sans;
    justify-content: center;
    font-weight: 700;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 35px;
    color: #EE502D;

    /* border: 1px solid red; */
}

.market-star-result-data h4 {
    display: flex;
    font-family: Inria Sans;
    justify-content: center;
    font-weight: 700;
    letter-spacing: 1px;
    /* margin-top: 16px; */
    margin-bottom: 0px;
    line-height: 35px;
    color: #EE502D;

    /* border: 1px solid red; */
}

.star {
    font-family: Inria Sans;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 4px;
    color: #0F1E36;
    line-height: 20px;
    margin-top: 7px;
    /* border: 1px solid red; */
}

.dash hr {
    width: 12px;
    margin: 10px 10px;
    border-top: 4px solid;
    border-radius: 5px;
    opacity: 1;
    color: #0F1E36;
}

.market-result-box-content,
.market-result-box-name {
    z-index: 1;
}

.chart-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 23px;
    align-items: center;
}
 .newchart-btn {
    margin-top: 18px !important;
}

.chart-btn h5 {
    color: #FFF;
    font-size: 17px;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
}

.chart-btn h5:hover {
    cursor: pointer;
}

.chart-btn span {
    color: white;
    font-size: 25px;
    line-height: 25px;
}

.play-btn {
    position: absolute;
    bottom: 10px;
    /* border: 1px solid red; */
}

.play-btn-img:hover {
    cursor: pointer;
}

.play-btn-img {
    max-width: 55px;
    margin-left: 3px;
}

.play-btn-img img {
    width: 100%;
    /* filter: grayscale(100%); */
    object-fit: cover;
}

.disabled {
    filter: grayscale(100%);

}

.play-btn h6 {
    color: #FFF;
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    margin-top: 2px;
}


.newdash {
    margin-top: 5px;
}

.newstar {
    margin-top: 11px !important;
}







/* ###################################### media quary ############################################################################### */



@media only screen and (max-width:1230px) {

    .market-result .container {
        max-width: 1140px;
    }
}

@media only screen and (max-width:1152px) {

    .market-result-box-name h3 {
        font-size: 22px;
    }

    .chart-btn {
        margin-top: 22px;
    }

    .newchart-btn {
        margin-top: 18px !important;
    }

    .chart-btn h5 {
        font-size: 16px;
    }

    .star {
        font-size: 32px;
    }

    .dash hr {
        width: 10px;

    }

}

@media only screen and (max-width:1024px) {


    .market-result-box-name h3 {
        font-size: 20px;
    }

    .chart-btn h5 {
        font-size: 14px;
    }

    .star {
        font-size: 30px;
    }

    .dash hr {
        width: 10px;

    }

    .open-time h6,
    .close-time h6 {
        font-size: 14px;
    }

}

@media only screen and (max-width:991px) {

    .market-result-heading {
        max-width: 340px;
    }
    
    .market-result-heading h2 ,.market-result-heading h2 span{
        font-size: 65px;
    }
    

    .market-result-box {
        padding: 15px 15px;
    }

    .market-result-box-name h3 {
        font-size: 17px;
    }

    .chart-btn h5 {
        font-size: 13px;
    }

    .star {
        font-size: 28px;
    }

    .dash hr {
        width: 10px;

    }

    .open-time h6,
    .close-time h6 {
        font-size: 12px;
    }

    .chart-btn {
        margin-top: 24px;
    }

    .market-result-box-name {
        width: 40%;
    }

    .market-result-box-content {
        width: 60%;
    }




}

@media only screen and (max-width:850px) {

    .market-result-box-name {
        width: 40%;
    }

    .market-result-box-content {
        width: 60%;
    }

    .market-result-box-name h3 {
        font-size: 15px;
    }


}

@media only screen and (max-width:767px) {

    .market-result-heading {
        max-width: 310px;
    }
    
    .market-result-heading h2 ,.market-result-heading h2 span{
        font-size: 60px;
    }

    .market-result-box {
        width: 70%;
    }

    .market-result-box-name,
    .market-result-box-content {
        width: 50%;
    }

    .market-result-box {
        padding: 15px 30px;
    }

    .market-result-box-name h3 {
        font-size: 18px;
    }


}

@media only screen and (max-width:680px) {
    .market-result-box {
        width: 80%;
    }
}

@media only screen and (max-width:576px) {

    .market-result-heading {
        max-width: 290px;
    }
    
    .market-result-heading h2 ,.market-result-heading h2 span{
        font-size: 55px;
    }

    .market-result-box {
        width: 90%;
    }

    .market-result-box-name {
        width: 45%;
    }

    .market-result-box-content {
        width: 55%;
    }

    .market-result-box {
        padding: 15px 15px;
    }
}

@media only screen and (max-width:380px) {

    .market-result-heading {
        max-width: 240px;
    }
    
    .market-result-heading h2 ,.market-result-heading h2 span{
        font-size: 45px;
    }

    .market-result-box {
        width: 95%;
    }

    .market-result-box-name h3 {
        font-size: 16px;
    }

    .chart-btn h5 {
        font-size: 12px;
    }

    .star {
        font-size: 26px;
    }

    .dash hr {
        width: 8px;

    }

    .market-result-box-name {
        width: 40%;
    }

    .market-result-box-content {
        width: 60%;
    }



}

@media only screen and (max-width:320px) {

    .market-result .container {
        max-width: 1140px;
        width: 100% !important;
        padding-left: 0px;
        padding-right: 0px;
    }

    .market-result-box {
        width: 100%;
    }


    .play-btn-img {
        max-width: 45px;
    }

    .play-btn h6 {
        font-size: 12px;
    }


}