.starline-chart {
    padding: 50px 0px;
    /* position: relative; */
}

.starline-chart-content {
    max-width: 90%;
    width: 90%;
    margin: 0 auto;
}

.starline-chart-content-title {
    background-color: #FFB541;
    text-align: center;
    padding: 10px 0px;
}

.starline-chart-content-title h3 {
    color: #000;
    font-family: Lato;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
}


.starline-chart-table {
    margin-top: 20px;
}

.starline-chart-table table,
th,
td {
    border: 3px solid white;
}

.starline-chart-table .table-head-date,
.starline-chart-table .table-head-time {
    background-color: #B5009F !important;
    vertical-align: middle;
    padding: 10px 0px !important;
}

.starline-chart-table .table-head-date {
    width: 10%;
}

.starline-chart-table.table-head-time {
    width: 7%;
}

.starline-chart-table .table-head-date h5 {
    font-family: lato;
    font-weight: 700;
    color: white;
    margin-bottom: 0px;
}

.starline-chart-table .table-head-time h6 {
    font-family: lato;
    font-weight: 700;
    color: white;
    margin-bottom: 0px;
    letter-spacing: 1px;
}

.starline-chart-table .table-date {
    background-color: #CC00B3 !important;
    vertical-align: middle;
    padding: 10px 0px;
}

.starline-chart-table .table-date h6 {
    font-family: lato;
    font-weight: 700;
    color: white;
    margin: 8px;
}

.table-body td {
    background-color: #FFE2FC !important;
    vertical-align: middle;
}

.starline-chart-table .table-result h6 {
    font-family: lato;
    font-weight: 700;
    /* margin: 8px; */
}

.starline-result-ank h6 {
    font-family: lato;
    font-size: 14px;
    letter-spacing: 1px;
}

.starline-main-result h6 {
    font-family: lato;
    font-weight: 700;
    font-size: 25px;
}

.starline-result-ank .star {
    font-size: 26px;
}

.starline-main-result .star {
    margin-top: 10px !important;
}


.loader {
    /* position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0000009c;
    z-index: 9999; */

    min-height: 300px;

    display: flex;
    justify-content: center;
}






/* ###################################### media quary ############################################################################### */


@media only screen and (max-width:1366px) {

    .starline-chart-table .table-date h6 {
        font-size: 15px !important;
    }

}

@media only screen and (max-width:1280px) {


    .starline-chart-table .table-date h6 {
        font-size: 13px !important;
    }

    .starline-chart-table .table-head-date h5 {
        font-size: 18px;
    }

    .starline-chart-table .table-head-time h6 {
        font-size: 14px;

    }


}


@media only screen and (max-width:1035px) {

    .starline-chart-table {
        overflow-x: scroll;
    }

    .starline-chart-table table {
        width: 920px;
    }

}

.old-data .dateFirst {
    background-color: #CC00B3 !important;
    vertical-align: middle;
    padding: 10px 0px;
    font-family: lato;
    font-weight: 700;
    color: white;
    letter-spacing: 0px;
    font-size: 1rem;
}

.old-data td {
    background-color: #FFE2FC !important;
    vertical-align: middle;
    font-family: lato;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
}

.old-data td .txtB {
    font-size: 25px;
    font-family: lato;
    font-weight: 700;
}

.old-data .txtA {
    font-family: lato;
    font-weight: 700;
    letter-spacing: 0px;
}