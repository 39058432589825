.starline-result {
    padding: 50px 0px;
}

.starline-result .underline {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.starline-result-heading {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
}

.starline-result-heading h2 {
    margin-bottom: 0;
    font-family: 'Jomhuria', cursive;
    font-size: 70px;
    color: #00365C;
    text-transform: capitalize;
    line-height: 40px;
}

.starline-result-heading h2 span {
    font-family: 'Jomhuria', cursive;
    font-size: 70px;
    line-height: 40px;
    color: #E100C6;
}

.starline-result-heading hr {
    color: #FF9B00;
    margin: 0;
    border: 0;
    border-top: 3px solid;
    opacity: 1;
}

.current-date {
    text-align: center;
    margin-top: 20px;
}

.current-date h4 {
    color: #0F1E36;
    font-family: Inria Sans;
    font-weight: 700;
}

.starline-result-table {
    margin-top: 30px;
    display: flex;
    justify-content: center;

}

.starline-result-table-bg {
    position: relative;
    width: 45%;
    margin: 20px;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    z-index: 12;

}

.starline-result-table-time,
.starline-result-table-result {
    width: 33%;

}

.starline-result-table-title {
    z-index: 15;
    height: 50px;
    border: 2px solid #FF00E0;
    background: #B903AC;
    margin-top: -20px;
    text-align: center;
    padding: 10px 0px
}

.starline-result-table-title h4 {
    color: #FFF;
    font-family: Jost;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.starline-result-tabale-time-data, .starline-result-tabale-result-data{
    width: 35%;
    text-align: center;
    border-bottom: 2px solid #BDBDBD;
}

.starline-result-tabale-time-data h5 {
    color: #00365C;
    font-family: Inria Sans;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
}

.starline-result-tabale-result-data h5{
    color: #EE502D;
    font-family: Inria Sans;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
}

.table-horizontal-line{
    margin-top: 45px;
}

.table-horizontal-line hr{
    height: 95%;
    margin: auto;
    border: 1px solid #BDBDBD;
    opacity: 1;
}

.table-result-title-bg{
    width: 100%;
    z-index: -1;
    position: absolute;
    top: -20px;
    display: flex;
    justify-content: space-around;
}

.starline-result-table-title-bg {
    background-color: #580084;
    width: 42%;
    height: 20px;
    clip-path: polygon(8% 0%, 92% 0%, 97% 100%, 3% 100%);

}



.starline-result-table-title-row{
    display: flex;
    justify-content: space-around;

}

.starline-result-table-data-row{
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}


.starline-result-table-title-time ,.starline-result-table-title-result{
    z-index: 15;
    width: 35%;
    height: 50px;
    border: 2px solid #FF00E0;
    background: #B903AC;
    margin-top: -20px;
    text-align: center;
    padding: 10px 0px;


}
.starline-result-table-title-time h4,.starline-result-table-title-result h4{
    color: #FFF;
    font-family: Jost;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.table-vertical-line{
    position: absolute;
    top: 0%;
    left: 50%;
    height: 100%;
}

.table-vertical-line hr{
    height: 87%;
    margin: 30px 0px;
    border: 0;
    border: 1px solid #BDBDBD;
    opacity: 1;
}

.starline-result-table .star-data{
    display: flex;
    justify-content: center;
    font-size: 22px;
}

.starline-result-table .star-data .star{
    font-size: 30px;
}
.starline-result-table .star-data .dash hr {
    width: 10px;
    border-top: 3px solid;
}

.starline-result-data-content{
    display: flex;
    flex-direction: column;
}

.starline-result-table-data-row:last-child .starline-result-tabale-result-data,
.starline-result-table-data-row:last-child .starline-result-tabale-time-data {
  border-bottom: none;
}



.chartzone-btn {
    max-width: 200px;
    margin: auto;
    margin-top: 30px;
    padding: 3px;
    border-radius: 6px;
    border: 1px solid black;
    background-color: transparent;
}

.chartzone-btn:hover{
    cursor: pointer;
}

.chartzone-content {
    margin: auto;
    padding: 10px 0px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(90deg, #D32E0A 0%, #EE502D 100%);
    text-align: center;
    position: relative;
}

.chartzone-content h5 {
    width: 90%;
    margin: auto;
    margin-bottom: 0px;
    vertical-align: middle;
    font-family: 'Lato', sans-serif;
    color: white;
    font-weight: 800;
    text-transform: capitalize;
    letter-spacing: 0.5px;

}

.chartzone-content .shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.chartzone-content .shade img {
    width: 120%;
    height: 100%;
    object-fit: contain;
}




/* ###################################### media quary ############################################################################### */


@media only screen and (max-width:1230px) {
    
    .starline-result .container{
        max-width: 1140px;
    }

}

@media only screen and (max-width:991px) {

    .starline-result-heading {
        max-width: 360px;
    }
    
    .starline-result-heading h2 ,  .starline-result-heading h2 span {
        font-size: 65px;
    }
    
}

@media only screen and (max-width:850px) {

    .starline-result-table {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .starline-result-table-bg {
        width: 60%;
        margin-top: 30px;
    }

    .starline-result-table .star-data .star{
        font-size: 25px;
    }
    .starline-result-table .star-data .dash hr {
        width: 8px;
        border-top: 2px solid;
        margin-top: 14px;
    }

    .starline-result-tabale-time-data h5 {
        font-size: 18px;
        margin-bottom: 15px;
    }
    
    .starline-result-tabale-result-data h5{
        font-size: 18px;
       
    }

}

@media only screen and (max-width:680px) {

    .starline-result-table-bg {
        width: 80%;
        margin-top: 30px;
    }
}

@media only screen and (max-width:576px) {

    .starline-result-heading {
        max-width: 310px;
    }
    
    .starline-result-heading h2 ,  .starline-result-heading h2 span {
        font-size: 55px;
    }

    .starline-result-table-bg {
        width: 90%;
        margin-top: 30px;
    }

    .starline-result-table .star-data .star{
        font-size: 22px;
    }
    .starline-result-table .star-data .dash hr {
        width: 8px;
        border-top: 2px solid;
        margin-top: 13px;
    }

    .starline-result-tabale-time-data h5 {
        font-size: 16px;
        margin-bottom: 15px;
    }
    
    .starline-result-tabale-result-data h5{
        font-size: 16px;
       
    }
}

@media only screen and (max-width:380px) {

    .starline-result-heading {
        max-width: 260px;
    }
    
    .starline-result-heading h2 ,  .starline-result-heading h2 span {
        font-size: 45px;
    }

}