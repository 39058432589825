@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:wght@700&family=Inter:wght@600&family=Jomhuria&family=Jost:wght@400;500;600;700&family=Lato:wght@400;700;900&family=Lilita+One&display=swap');

.addmargin {
    margin-top: 90px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


.animate-spin {
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}




/* ###################################### media quary ############################################################################### */



@media only screen and (max-width:1920px) {}

@media only screen and (max-width:1680px) {}

@media only screen and (max-width:1540px) {}

@media only screen and (max-width:1440px) {}

@media only screen and (max-width:1366px) {}

@media only screen and (max-width:1230px) {}

@media only screen and (max-width:1152px) {}

@media only screen and (max-width:1080px) {}

@media only screen and (max-width:1024px) {}

@media only screen and (max-width:991px) {}

@media only screen and (max-width:850px) {}

@media only screen and (max-width:767px) {}

@media only screen and (max-width:680px) {}

@media only screen and (max-width:576px) {}

@media only screen and (max-width:380px) {}