.rate-chart {
    padding: 50px 0px;
}

.rate-chart-content {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
}

.rate-chart-heading {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    /* border: 1px solid red; */
}

.rate-chart-heading h2 {
    margin-bottom: 0;
    font-family: 'Jomhuria', cursive;
    font-size: 70px;
    color: #00365C;
    text-transform: capitalize;
    line-height: 40px;
}

.rate-chart-heading h2 span {
    font-family: 'Jomhuria', cursive;
    font-size: 70px;
    line-height: 40px;
    text-transform: uppercase;
    color: #E100C6;
}

.rate-chart-heading hr {
    color: #FF9B00;
    margin: 0;
    border: 0;
    border-top: 3px solid;
    opacity: 1;
}

.underline {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.underline::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #FF9B00;
    transform: rotate(45deg);
    top: -5px;
    left: 0;
}

.underline::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #FF9B00;
    transform: rotate(45deg);
    top: -5px;
    right: 0;
}

.starline-rate {
    margin-top: 50px;
    text-align: center;
}

.market-rate {
    margin-top: 40px;
    text-align: center;
}


.starline-rate-heading h3,
.market-rate-heading h3 {
    font-family: 'Lilita One', cursive;
    text-transform: uppercase;
    color: #EE502D;
}

.starline-rate-boxes,
.market-rate-boxes {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.rate-box-bg {
    width: 23%;
    background: linear-gradient(90deg, #A60092, #580084, #A60092, #580084, #A60092);
    border-radius: 25px;
    padding: 8px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
    margin: 15px 10px;
}

.rate-box-content {
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 18px;
    padding: 5px;
}

.rate-box-data {
    border-radius: 15px;
    border: 2px dashed #BABABA;
    padding: 10px;

}

.rate-box-data h4 {
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    color: #EE502D;
    margin-bottom: 0px;
}

.rate-box-data h5 {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #00365C;
    margin-top: 10px;
    margin-bottom: 0px;
}




/* ###################################### media quary ############################################################################### */




@media only screen and (max-width:1152px) {

    .rate-box-bg {
        width: 22%;
    }

    .rate-box-data h4 {
        font-size: 20px;
    }

    .rate-box-data h5 {
        font-size: 18px;
    }
}

@media only screen and (max-width:1080px) {}

@media only screen and (max-width:1024px) {}

@media only screen and (max-width:991px) {

    .rate-chart-heading {
        max-width: 370px;
    }

    .rate-chart-heading h2 , .rate-chart-heading h2 span{
        font-size: 65px;
    }

    .rate-box-bg {
        width: 22%;
    }

    .rate-box-data h4 {
        font-size: 18px;
    }

    .rate-box-data h5 {
        font-size: 16px;
    }
}

@media only screen and (max-width:850px) {
    .rate-box-data h4 {
        font-size: 16px;
    }

    .rate-box-data h5 {
        font-size: 14px;
    }
}

@media only screen and (max-width:767px) {

    .rate-chart-heading {
        max-width: 350px;
    }

    .rate-chart-heading h2 , .rate-chart-heading h2 span{
        font-size: 60px;
    }

    .rate-chart-content {
        max-width: 95%;
        width: 100%;
        margin: 0 auto;
    }


    .rate-box-bg {
        width: 35%;
    }
}

@media only screen and (max-width:680px) {
    .rate-box-bg {
        width: 40%;
    }
}

@media only screen and (max-width:576px) {

    .rate-chart-heading {
        max-width: 320px;
    }

    .rate-chart-heading h2 , .rate-chart-heading h2 span{
        font-size: 55px;
    }

    .rate-box-bg {
        width: 42%;
        margin: 10px 8px;

    }

    .rate-box-data h4 {
        font-size: 17px;
    }

    .rate-box-data h5 {
        font-size: 14px;
    }
}


@media only screen and (max-width:420px) {
    .rate-box-data h4 {
        font-size: 15px;
    }

    .rate-box-data h5 {
        font-size: 14px;
    }


}

@media only screen and (max-width:380px) {

    .rate-chart-heading {
        max-width: 260px;
    }

    .rate-chart-heading h2 , .rate-chart-heading h2 span{
        font-size: 45px;
    }

    .rate-box-bg {
        width: 70%;
        margin: 10px 8px;

    }

    .rate-box-data h4 {
        font-size: 14px;
    }

    .rate-box-data h5 {
        font-size: 12px;
    }

    
}

@media only screen and (max-width:250px) {

    .rate-box-bg {
        width: 80%;
        margin: 10px 8px;

    }


    
}