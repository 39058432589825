.floating-btn{
    position: fixed;
    left: 0;
    bottom: 5%;
    z-index: 9999;
    width: 160px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 65px;
    background: rgb(255, 199, 0, 0.8) ;
    border-top: 3px  solid #fc8038;
    border-right: 3px  solid #fc8038;
    border-bottom: 3px  solid #fc8038;
    padding: 7px 0px;

    /* opacity: 0.8; */
}

.social-icons{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 10px;
}

.whatsapp{
    width: 45px;
    height: 45px;
    border-radius: 8px;
    /* padding: 6px 5px 6px 5px; */
    background: linear-gradient(180deg, #35DB23 0%, #27A11A 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}

.telegram{
    width: 45px;
    height: 45px;
    border-radius: 8px;
    background: linear-gradient(180deg, #1C8ADB 0%, #006DBD 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsapp img , .telegram img{
    width: 60%;
    height: 60%;
    object-fit: contain;
    margin-bottom: 3px;
}

.whatsapp:hover , .telegram:hover{
    cursor: pointer;
}

